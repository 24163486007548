footer {
    background: linear-gradient(0.25turn, #111111, #000000);
    color: white;
    padding: 20px;
    text-align: center;
}

.footer-text-container {
    margin-bottom: 2rem;
}

.social-buttons-container {
    margin: 10px;
}

.footer-link {
    margin: 0 20px;
    color: white;
    text-decoration: none;
}


