
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html {
    --color-black: #000000;
    --color-white: #ffffff;
    --color-black-shade: rgba(255,255,255,0.5);
    --color-white-shade: #eeeeee;
    --color-yellow: rgba(255,215,0,0.2);
    --color-black-darker-shade: rgba(255,255,255,0.2)
}

main {
    min-height: 80vh;
}

body {
    font-family: BlinkMacSystemFont,-apple-system,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    word-spacing: 3px;
    letter-spacing: 1px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: rgb(255,215,0);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiTypography-root {
    font-family: 'Dancing Script, cursive';
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
