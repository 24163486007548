
.nav-link-container {
    padding: 10px 0;
}
.brand-container {
    padding: 10px 0;
}
.nav-link {
    margin: 0px 20px;
    color: #17265c;
    text-decoration: none;
}

.nav-link-brand {
    margin: 10px 20px;
    color: white;
    text-decoration: none;
}

.nav-link:hover {
    background-color: #213785;
    color: #ffffff;
    border-radius: 10%;
    cursor: pointer;
}

nav {
    height: 10vh;
    color: #17265c;
    z-index: 2;
}


