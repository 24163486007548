/* Toast.css */
.toast {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  z-index: 1000;
  opacity: 0.9;
  transition: opacity 0.5s ease, transform 0.5s ease;
  max-width: 90%; /* Ensures it's mobile-friendly */
  box-sizing: border-box;
  text-align: center;
}

.toast.success {
  background-color: #05d31f;
}

.toast.error {
  background-color: #d5c28f;
  color: #000000;
}

.toast.info {
  background-color: #213785;
}

@media (max-width: 600px) {
  .toast {
    font-size: 14px;
    padding: 8px 16px;
  }
}
